<template>
  <div class="md-example-child md-example-child-tabs md-example-child-tab-bar-4">
    <md-tab-bar
      v-model="current"
      :items="items"
      :has-ink="false"
    >
      <template slot="item" slot-scope="{ item }">
        <!-- <router-link :to="item.to" active-class="active"> -->
          <div class="custom-item" @click="routerchange(item.to)">
            <div class="icon">
              <img class="img" :src="$route.path == item.to?item.src[1]:item.src[0]" />
            </div>
            <div class="text">
              <span :class="$route.path == item.to? 'active':'textSty'"
                  :style="$route.path == item.to? `color:${colorprimary}` :''"
		  v-text="item.label"></span>
            </div>
          </div>
        <!-- </router-link> -->
      </template>
    </md-tab-bar>
  </div>
</template>

<script>
import { getStorage } from '@/lib/util'
import config from '@/config'
export default {
  name: 'tab-bar-demo',
  data () {
    return {
      current: 1,
      user: {},
      rytype: '',
      empno: '',
      suserid: '',
      frompath: '',
      colorprimary: '',
      items: [
        {
          name: 1,
          label: '名片',
          to: '/visitingcard',
          // src: [require('@/assets/abt/img/mp.png'), getStorage('imgs', '') ? getStorage('imgs', '').mpicon : require('@/assets/abt/img/mptrue.png')]
          src: [require('@/assets/abt/img/mp.png'), require('@/assets/abt/img/mptrue.png')]
        },
        { name: 2,
          label: '微官网',
          to: '/website',
          src: [require('@/assets/abt/img/wgw.png'), require('@/assets/abt/img/wgwture.png')]
        }
      ]
    }
  },
  created () {
    this.colorprimary = getStorage('theme', '')
    this.items[0].src[1] = getStorage('ossurl', '') + getStorage('imgs', {}).mpicon
    this.items[1].src[1] = getStorage('ossurl', '') + getStorage('imgs', {}).wgw
    this.user = getStorage('u_s', {})
    this.empno = this.$route.query.empno ? this.$route.query.empno : this.user.empno
    this.rytype = this.$route.query.rytype ? this.$route.query.rytype : this.user.rytype
    this.suserid = this.$route.query.suserid ? this.$route.query.suserid : this.user.userid
    this.frompath = getStorage('r_t', '')
  },
  methods: {
    routerchange (val) {
      let beg = this.frompath.indexOf('/')
      let end = this.frompath.indexOf('?')
      let path = this.frompath.substr(beg + 1, end - beg - 1)
      // console.log(path)
      if (path == 'visitingcard') {
        if (val == '/website') {
          // console.log(111)
          if (this.$route.query.sno) {
            // this.$router.push({ path: `${val}?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.suserid}&type=12&sno=${this.$route.query.sno}` })
            location.replace(`${config.redirect_uri}${val}?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.suserid}&type=12&sno=${this.$route.query.sno}`)
          } else {
            location.replace(`${config.redirect_uri}${val}?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.suserid}&type=12`)
          }
        } else {
          if (this.$route.query.sno) {
            location.replace(`${config.redirect_uri}${val}?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.suserid}&type=9&sno=${this.$route.query.sno}`)
          } else {
            location.replace(`${config.redirect_uri}${val}?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.suserid}&type=9`)
          }
        }
      } else {
        if (val == '/website') {
          if (this.$route.query.sno) {
            this.$router.push({ path: `${val}?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.suserid}&type=12&sno=${this.$route.query.sno}` })
          } else {
            this.$router.push({ path: `${val}?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.suserid}&type=12` })
          }
        } else {
          if (this.$route.query.sno) {
            this.$router.push({ path: `${val}?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.suserid}&type=9&sno=${this.$route.query.sno}` })
          } else {
            this.$router.push({ path: `${val}?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.suserid}&type=9` })
          }
        }
      }
    }
  }
}

</script>

<style lang="stylus">
.md-tab-bar
  box-shadow:0 -2px 3px -1px  rgba(0,0,0,0.4);
  z-index: 199;
.md-example-child-tab-bar-4
  .custom-item
    margin-top 25px
    margin-bottom 25px
    z-index: 199;
    display flex
    flex-direction column
    align-items center
    justify-content center
    height 100%
    flex 1
    .text
      font-size 24px
.img
  width 60px
  height 60px
.textSty
  font-size: 32px;
  color: #B9B9B9
.active
  // color: color-primary!important;
  font-size: 32px;
  font-weight: bold;
a
  text-decoration: none;
</style>
