/*
 * @Author: 黄孝娟
 * @Date: 2019-11-07 16:05:48
 * @Last Modified by: 黄孝娟
 * @Last Modified time: 2019-11-07 17:25:10
 */
export default {
  methods: {
    // 初始化websocket
    initWebSocket(val, val1) {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        if (val - 0 > 0) {
          // console.log(config.Websocket_uri)
          // 南华测试
          const wsuri = `ws://120.79.213.190:14365/ws?id=${val}&comid=${val1}`;
          // 南华uat
          // const wsuri = `ws://120.79.220.78:14365/ws?id=${val}&comid=${val1}`
          // 南华正式
          // const wsuri = `ws://47.113.109.50:14365/ws?id=${val}&comid=${val1}`;

          this.websock = new WebSocket(wsuri);
          this.websock.onopen = this.websocketonopen;
          this.websock.onerror = this.websocketonerror;
          this.websock.onmessage = this.websocketonmessage;
          // this.websock.onclose = this.websocketclose
        }
      }
    },
    test() {
      console.log("每隔9秒钟执行一次");
      this.websock.send("心跳测试");
    },
    websocketonopen() {
      console.log("Websocket连接成功");
      this.dsq = setInterval(this.test, 9000);
    },
    websocketonerror() {
      console.log("Websocket连接发生错误");
    },
    websocketonmessage(e) {
      // 数据接收
      const redata = JSON.parse(e.data);
      console.log(redata);
    },
    websocketclose() {
      // 关闭
      clearInterval(this.dsq);
      this.websock.close();
      console.log("断开连接");
    }
  }
};
