<template>
  <div class="content">
    <div class="scroll">
      <div v-if="empno == user.empno"
           class="edit_box"
           @click="toEdit"
           :style="`background:${colorprimary}`">
        <img src="@/assets/abt/img/editbg.png"
             alt=""
             class="edit_img" />
        编辑
      </div>
      <div class="hidden">
        <div class="bgImg"
             v-if="form.cardstyle == '1'"
             ref="card01">
          <div class="cardbg">
            <img class="cardbg1 block"
                 src="@/assets/abt/img/card01bg.png"
                 alt="" />
            <img class="logo1"
                 :src="mplogo"
                 alt="" />
            <div class="cardbg2">
              <p>{{ form.cardempname }}</p>
              <p class="carddegre">{{ form.carddegreeno }}</p>
            </div>
            <div class="cardbg3"
                 v-show="form.cardmobile && form.cardmobile != ''">
              <md-icon name="phone"
                       color="white"
                       size="lg"></md-icon>
              <span> {{ form.cardmobile }}</span>
            </div>
            <div class="cardbg4"
                 v-show="form.email && form.email != ''">
              <md-icon name="id-card"
                       color="white"
                       size="lg"></md-icon>
              <span> {{ form.email }}</span>
            </div>
            <div class="cardbg5"
                 v-show="form.workarea && form.workarea != ''">
              <md-icon name="location"
                       color="white"
                       size="lg"></md-icon>
              <span> {{ form.workarea }}</span>
            </div>
            <div class="cardbg6">
              <img class="img"
                   :src="form.headimg"
                   alt="" />
            </div>
            <div class="cardbg7">
              <simple-cropper :initParam="uploadParam"
                              :successCallback="uploadHandle"
                              ref="cropper"
                              class="box">
                <img class="img"
                     :src="mpurl" />
              </simple-cropper>
            </div>
          </div>
        </div>
        <div class="bgImg"
             v-if="form.cardstyle == '2'"
             ref="card02">
          <div class="cardbg">
            <img class="cardbg1 block"
                 src="@/assets/abt/img/card2bg.png"
                 alt="" />
            <img class="logo2"
                 :src="mplogo"
                 alt="" />
            <div class="cardbg2_1">
              <p>{{ form.cardempname }}</p>
              <p class="carddegre">{{ form.carddegreeno }}</p>
            </div>
            <div class="cardbg3_1"
                 v-show="form.cardmobile && form.cardmobile != ''">
              <md-icon name="phone"
                       color="#0066b3"
                       size="lg"></md-icon>
              <span> {{ form.cardmobile }}</span>
            </div>
            <div class="cardbg4_1"
                 v-show="form.email && form.email != ''">
              <md-icon name="id-card"
                       color="#0066b3"
                       size="lg"></md-icon>
              <span> {{ form.email }}</span>
            </div>
            <div class="cardbg5_1"
                 v-show="form.workarea && form.workarea != ''">
              <md-icon name="location"
                       color="#0066b3"
                       size="lg"></md-icon>
              <span> {{ form.workarea }}</span>
            </div>
            <div class="cardbg6_1">
              <img class="img"
                   :src="form.headimg"
                   alt="" />
            </div>
            <div class="cardbg7_1">
              <simple-cropper :initParam="uploadParam"
                              :successCallback="uploadHandle"
                              ref="cropper"
                              class="box">
                <img class="img"
                     :src="mpurl" />
              </simple-cropper>
            </div>
          </div>
        </div>
        <div class="bgImg"
             v-if="form.cardstyle == '3'"
             ref="card03">
          <div class="cardbg">
            <img class="cardbg1 block"
                 src="@/assets/abt/img/card3bg.png"
                 alt="" />
            <img class="logo3"
                 :src="mplogo"
                 alt="" />
            <div class="cardbg2_2">
              <p> {{ form.cardempname }}</p>
              <p class="carddegre">{{ form.carddegreeno }}</p>
            </div>
            <div class="cardbg3_2"
                 v-show="form.cardmobile && form.cardmobile != ''">
              <md-icon name="phone"
                       color="rgb(217, 212, 157)"
                       size="lg"></md-icon>
              <span> {{ form.cardmobile }}</span>
            </div>
            <div class="cardbg4_2"
                 v-show="form.email && form.email != ''">
              <md-icon name="id-card"
                       color="rgb(217, 212, 157)"
                       size="lg"></md-icon>
              <span> {{ form.email }}</span>
            </div>
            <div class="cardbg5_2"
                 v-show="form.workarea && form.workarea != ''">
              <md-icon name="location"
                       color="rgb(217, 212, 157)"
                       size="lg"></md-icon>
              <span> {{ form.workarea }}</span>
            </div>
            <div class="cardbg6_2">
              <img class="img"
                   :src="form.headimg"
                   alt="" />
            </div>
            <div class="cardbg7_2">
              <simple-cropper :initParam="uploadParam"
                              :successCallback="uploadHandle"
                              ref="cropper"
                              class="box">
                <img class="img"
                     :src="mpurl" />
              </simple-cropper>
            </div>
          </div>
        </div>
      </div>

      <div class="bgImg"
           v-if="form.cardstyle == '1'"
           ref="card1">
        <div class="cardbg">
          <img class="cardbg1 block"
               src="@/assets/abt/img/card01bg.png"
               alt="" />
          <img class="logo1"
               :src="mplogo"
               alt="" />
          <div class="cardbg2">
            <p>{{ form.cardempname }}</p>
            <p class="carddegre">{{ form.carddegreeno }}</p>
          </div>
          <div class="cardbg3"
               v-show="form.cardmobile && form.cardmobile != ''">
            <md-icon name="phone"
                     color="white"
                     size="lg"></md-icon>
            <span> {{ form.cardmobile }}</span>
          </div>
          <div class="cardbg4"
               v-show="form.email && form.email != ''">
            <md-icon name="id-card"
                     color="white"
                     size="lg"></md-icon>
            <span> {{ form.email }}</span>
          </div>
          <div class="cardbg5"
               v-show="form.workarea && form.workarea != ''">
            <md-icon name="location"
                     color="white"
                     size="lg"></md-icon>
            <span> {{ form.workarea }}</span>
          </div>
          <div class="cardbg6">
            <img class="img"
                 :src="form.headimg"
                 alt="" />
          </div>
          <div class="cardbg7">
            <simple-cropper :initParam="uploadParam"
                            :successCallback="uploadHandle"
                            ref="cropper"
                            class="box">
              <img class="img"
                   :src="ossurl + form.wxewmurl"
                   @click="upload('cropper')"
                   v-if="form.wxewmurl" />
              <img class="img"
                   v-show="empno==user.empno"
                   src="@/assets/abt/img/QRcode.png"
                   @click="upload('cropper')"
                   v-else />
            </simple-cropper>
          </div>
        </div>
      </div>
      <div class="bgImg"
           v-if="form.cardstyle == '2'"
           ref="card2">
        <div class="cardbg">
          <img class="cardbg1 block"
               src="@/assets/abt/img/card2bg.png"
               alt="" />
          <img class="logo2"
               :src="mplogo"
               alt="" />
          <div class="cardbg2_1">
            <p>{{ form.cardempname }}</p>
            <p class="carddegre">{{ form.carddegreeno }}</p>
          </div>
          <div class="cardbg3_1"
               v-show="form.cardmobile && form.cardmobile != ''">
            <md-icon name="phone"
                     color="#0066b3"
                     size="lg"></md-icon>
            <span> {{ form.cardmobile }}</span>
          </div>
          <div class="cardbg4_1"
               v-show="form.email && form.email != ''">
            <md-icon name="id-card"
                     color="#0066b3"
                     size="lg"></md-icon>
            <span> {{ form.email }}</span>
          </div>
          <div class="cardbg5_1"
               v-show="form.workarea && form.workarea != ''">
            <md-icon name="location"
                     color="#0066b3"
                     size="lg"></md-icon>
            <span> {{ form.workarea }}</span>
          </div>
          <div class="cardbg6_1">
            <img class="img"
                 :src="form.headimg"
                 alt="" />
          </div>
          <div class="cardbg7_1">
            <simple-cropper :initParam="uploadParam"
                            :successCallback="uploadHandle"
                            ref="cropper"
                            class="box">
              <img class="img"
                   :src="ossurl + form.wxewmurl"
                   @click="upload('cropper')"
                   v-if="form.wxewmurl" />
              <img class="img"
                   src="@/assets/abt/img/QRcode.png"
                   v-show="empno==user.empno"
                   @click="upload('cropper')"
                   v-else />
            </simple-cropper>
          </div>
        </div>
      </div>
      <div class="bgImg"
           v-if="form.cardstyle == '3'"
           ref="card3">
        <div class="cardbg">
          <img class="cardbg1 block"
               src="@/assets/abt/img/card3bg.png"
               alt="" />
          <img class="logo3"
               :src="mplogo"
               alt="" />
          <div class="cardbg2_2">
            <p> {{ form.cardempname }}</p>
            <p class="carddegre">{{ form.carddegreeno }}</p>
          </div>
          <div class="cardbg3_2"
               v-show="form.cardmobile && form.cardmobile != ''">
            <md-icon name="phone"
                     color="rgb(217, 212, 157)"
                     size="lg"></md-icon>
            <span> {{ form.cardmobile }}</span>
          </div>
          <div class="cardbg4_2"
               v-show="form.email && form.email != ''">
            <md-icon name="id-card"
                     color="rgb(217, 212, 157)"
                     size="lg"></md-icon>
            <span> {{ form.email }}</span>
          </div>
          <div class="cardbg5_2"
               v-show="form.workarea && form.workarea != ''">
            <md-icon name="location"
                     color="rgb(217, 212, 157)"
                     size="lg"></md-icon>
            <span> {{ form.workarea }}</span>
          </div>
          <div class="cardbg6_2">
            <img class="img"
                 :src="form.headimg"
                 alt="" />
          </div>
          <div class="cardbg7_2">
            <simple-cropper :initParam="uploadParam"
                            :successCallback="uploadHandle"
                            ref="cropper"
                            class="box">
              <img class="img"
                   :src="ossurl + form.wxewmurl"
                   @click="upload('cropper')"
                   v-if="form.wxewmurl" />
              <img class="img"
                   src="@/assets/abt/img/QRcode.png"
                   v-show="empno==user.empno"
                   @click="upload('cropper')"
                   v-else />
            </simple-cropper>
          </div>
        </div>
      </div>

      <div class="call">
        <div class="phoneshadow"
             @click="callPhone">
          <a :href="'tel:' + form.cardmobile">
            <img :src="getStorage('ossurl','')+getStorage('imgs',{}).phone"
                 alt="" />
            <span>拨打电话</span>
            <p>{{ this.form.cardmobile }}</p>
          </a>
        </div>
        <div class="phoneshadow wechat"
             v-show="form.wxnumber && form.wxnumber != ''"
             @click="copyWxnumber(form.wxnumber)">
          <img :src="getStorage('ossurl','')+getStorage('imgs',{}).wxicon"
               alt="" />
          <span>加我微信</span>
          <p>{{ this.form.wxnumber }}</p>
        </div>
      </div>
      <div class="btn">
        <div class="cardBtn1"
             @click="shareCard"
             :style="`backgroundColor:${colorprimary}`">名片分享</div>
        <div class="cardBtn2"
             v-if="this.empno == this.user.empno"
             @click="showPoster"
             :style="`color:${colorprimary};border:1px solid ${colorprimary}`">
          名片海报
        </div>
        <div class="cardBtn2"
             v-else
             :style="`color:${colorprimary};border:1px solid ${colorprimary}`"
             @click="changePhone">交换手机号</div>
      </div>
      <div class="see">
        <div class="browse"
             v-show="viewnum - 0 > 0">
          <img v-for="(item, index) in viewlist"
               :key="index"
               :src="item.headimg"
               class="seeimg" />
          <br />
          <span>{{ viewnum }}人浏览</span>
        </div>
        <div class="like">
          <img :src="getStorage('ossurl','')+getStorage('imgs',{}).mpdz"
               v-if="form.isdz"
               @click="support($event,'cancel')"
               class="support" />
          <img src="@/assets/abt/img/dzq.png"
               @click="support($event,'add')"
               v-else
               class="support" />
          <p>
            靠谱 <span :style="`color:${colorprimary}`">{{ form.dznum }}</span>
          </p>
        </div>
      </div>
      <div class="intro"
           v-show="form.pdesc && form.pdesc != ''">
        <!-- <img src="@/assets/abt/img/grjjtb.png"
             alt=""
             class="titleimg" /> -->
        <div class="titleimg">
          <span>我的个人简介</span>
        </div>
        <div class="introcontent">
          <span>{{ this.form.pdesc }}</span>
        </div>
      </div>
      <div class="intro"
           v-show="form.photo && form.photo.length != 0">
        <!-- <img src="@/assets/abt/img/zptb.png"
             alt=""
             class="titleimg" /> -->
        <div class="titleimg">
          <span>我的照片</span>
        </div>
        <div class="introimg">
          <img class="myImg"
               v-for="(item, index) in form.photo"
               :key="index"
               v-show="form.photo.length-1>=0"
               :src="ossurl + item" />
        </div>
      </div>
      <!-- 名片海报 -->
      <md-landscape v-model="showCardPoster"
                    full-screen
                    class="posterlandscape">
        <!--:style="getStorage('imgs','')?'background-image: url('+getStorage('imgs').cylistbg+')':''"-->
        <div class="sharebox"
             :style="'background: url('+getStorage('ossurl','')+getStorage('imgs',{}).mphb+');background-size: contain;background-repeat: no-repeat;'">
          <div class="titPoster">
            <div class="business">
              <span class="card">递 名 片</span>
              <br />
              <span>BUSINESS CARD</span>
            </div>
            <div class="businessCard">
              <img class="makeimg"
                   :src="shareimg"
                   alt="" />
            </div>
            <div class="businessBottom">
              <span>长按名片保存后发送给朋友</span>
              <div @click="back"
                   :style="`backgroundColor:${colorprimary}`"><span>返回</span></div>
            </div>
          </div>
        </div>
      </md-landscape>
      <!-- <md-popup
        v-model="showCardPoster"
        position="right"
        style='width:100%;height:100%;overflow:auto;z-index:9999;'
      >
        <div class="md-example-popup md-example-popup-right">
          ddd
        </div>
      </md-popup> -->
    </div>
    <bottomtag class="bottomtag"></bottomtag>
    <div class="share"
         @click="cancelShare"
         v-show="isShareCard">
      <img src="@/assets/abt/img/fxbg.png" />
    </div>
    <md-dialog :closable="true"
               v-model="isChangePhoneShow"
               class="changeDialog">
      <div>
        <div class="applyItem">
          <span><span class="red">* </span>姓名：</span>
          <input v-model="phoneForm.username"
                 placeholder="请输入姓名" />
        </div>
        <div class="applyItem">
          <span><span class="red">* </span>手机：</span>
          <input type="text"
                 oninput="value=value.replace(/[^\d]/g,'')"
                 v-model="phoneForm.mobile"
                 maxlength="11"
                 placeholder="请输入手机号" />
        </div>
      </div>
      <div class="dialogbottom">
        <div @click="cancelChange"
             class="dialogBtn greyBtn">取消</div>
        <div @click="submitChange"
             class="dialogBtn"
             :style="`backgroundColor:${colorprimary}`">确定</div>
      </div>
    </md-dialog>
    <!-- <Consult @tozxzx="tozxzx"
             v-if="empno != user.empno"
             :empno="empno"
             :user="user">
    </Consult> -->
  </div>
</template>
<script>
import {
  selectUserCard,
  updateCard,
  exchangeMobile,
  updateDZ
} from '@/api/abt/customerOperation/visitingcard'
import { DoRecord } from '@/api/abt/customerOperation/common'
import bottomtag from '../component/bottomtag'
// import Consult from '@/views/abt/customerOperation/common/consulation/index'
import { Toast } from 'mand-mobile'
import { makeImg } from '@/lib/function'
// import { login, getToken, getWxURLInfo } from '@/api/basic'
import simpleCropper from '../component/simpleCropper'
import { getStorage } from '@/lib/util'
import initWebSocket from '@/mixins/websock'
import Clipboard from 'clipboard'
import { wechatshare } from '@/lib/wechat_share'
import config from '@/config'
export default {
  mixins: [initWebSocket],
  components: {
    bottomtag,
    simpleCropper,
    // Consult
  },
  data () {
    return {
      websock: null,
      user: {},
      ossurl: '',
      suserid: '',
      empno: '',
      rytype: '',
      uploadParam: {
        file: 'mpqrcode',
        type: 'mpqrcode',
        fileType: 'recruit', // 其他上传参数
        uploadURL: '/empno/getUpload', // 上传地址
        scale: 4, // 相对手机屏幕放大的倍数: 4倍
        ratio: 1 / 1
        // userid: localStorage.getItem(window.g.beforestr + '_userid'),
        // comid: localStorage.comid
      }, // 上传头像初始化数据
      height: '',
      // zd: require('@/assets/abt/img/dz.png'),
      zd: '',
      showCardPoster: false,
      form: {
        cardempname: '',
        cardsex: '',
        cardmobile: '',
        wxnumber: '',
        workarea: '',
        pdesc: '',
        carddegreeno: '',
        email: '',
        photo: [],
        wxewmurl: '',
        cardstyle: '1'
      },
      shareimg: '',
      isShareCard: false,
      viewnum: '',
      viewlist: [],
      mpurl: '',
      isChangePhoneShow: false,
      phoneForm: {
        username: '',
        mobile: ''
      },
      mplogo: '',
      colorprimary: '',
      dzend: true,
    }
  },
  created () {
    this.colorprimary = getStorage('theme', '')
    this.user = getStorage('u_s', {})
    this.ossurl = getStorage('ossurl', '')
    this.zd = this.ossurl + getStorage('imgs', {}).mpdz
    let logodata = getStorage('sys_info', {})
    this.mplogo = logodata.mplogo ? logodata.mplogo : ''
    this.empno = this.$route.query.empno
      ? this.$route.query.empno
      : this.user.empno
    this.rytype = this.$route.query.rytype
      ? this.$route.query.rytype
      : this.user.rytype
    this.suserid = this.$route.query.suserid
      ? this.$route.query.suserid
      : this.user.userid
    this.height = document.body.clientHeight
    this.getData()
    // this.getParams()
  },
  mounted () {
    document.querySelector('.scroll').style.height = `${this.height - 10}px`
  },
  destroyed: function () {
    this.websocketclose() // 页面销毁时关闭websocket连接
  },
  methods: {
    getData () {
      let data = {
        empno: this.empno,
        flag: '1',
        type: this.rytype
      }
      selectUserCard(data).then(res => {
        this.form = res.data.data
        if (!this.form.cardstyle) {
          this.form.cardstyle = '1'
        }
        this.mpurl = 'data:image/png;base64,' + res.data.data.mpurl
        if (res.data.data.headimgArray) {
          this.viewnum = res.data.data.headimgArray.total
          this.viewlist = res.data.data.headimgArray.rows
        }
        this.behaviorRecord()
        let shareurl = `${config.redirect_uri}/visitingcard?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.user.userid}&type=9`
        let fxstr = {
          suserid: this.suserid,
          empno: this.empno,
          otype: '2',
          btagcode: '9',
          rytype: this.rytype
        }
        if (this.$route.query.sno) {
          fxstr.sno = this.$route.query.sno
        }
        let path = ''
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          // ios
          if (this.$route.query.frompage == 'source') {
            path = config.redirect_uri + '/source'
          } else {
            path = config.redirect_uri
          }
        } else {
          // android
          path = window.location.href
        }
        wechatshare(
          `我是${this.form.cardempname}，这是我的AI名片，请惠存`,
          `感谢您对我的信赖与支持，希望成为您贴心的朋友！`,
          this.form.headimg,
          shareurl,
          path,
          fxstr
        )
      })
    },
    // 行为记录
    behaviorRecord () {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        otype: '1',
        btagcode: '9',
        rytype: this.rytype
      }
      if (this.$route.query.sno) {
        data.sno = this.$route.query.sno
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
      })
    },
    // 上传微信二维码
    upload (ele) {
      if (this.empno == this.user.empno) {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          console.log(this.$refs[ele].upload())
        }
        this.$refs[ele].upload()
      }
    },
    // 上传微信二维码成功回调
    uploadHandle (data) {
      this.form.wxewmurl = data.path
      updateCard(this.form).then(res => {
        Toast.succeed('上传成功')
      })
    },
    toEdit () {
      this.$router.push('/editcard')
    },
    support (e, type) {
      if (this.dzend) {
        console.log(type)
        this.dzend = false
        if (type == 'add') {
          if (this.empno == this.user.empno) {
            Toast.succeed('不能给自己点赞呦')
          } else {

            let data = {
              suserid: this.suserid,
              empno: this.empno,
              otype: '4',
              btagcode: '9',
              rytype: this.rytype
            }
            DoRecord(data).then(res => {
              updateDZ({ userid: this.user.userid, empno: this.empno, flag: '2' }).then((res) => {
                this.form.dznum += 1
                this.form.isdz = true
                var $i = $('<b>').html('<img src="' + this.zd + '" alt="" />')
                var x = e.pageX
                var y = e.pageY
                $i.css({
                  top: y - 20,
                  left: x,
                  position: 'absolute',
                  color: '#E94F06',
                  width: '25px',
                  height: '25px'
                })
                $('body').append($i)
                $i.animate(
                  {
                    top: y - 120,
                    opacity: 0,
                    'font-size': '1.4em'
                  },
                  1500,
                  function () { }
                )
                e.stopPropagation()
                this.dzend = true
              })
            })
          }
        } else {
          updateDZ({ userid: this.user.userid, empno: this.empno, flag: '1' }).then((res) => {
            this.form.dznum -= 1
            this.form.isdz = false
            this.dzend = true
          })
        }
      }
    },
    callPhone () {
      if (this.form.cardmobile) {
        let data = {
          suserid: this.suserid,
          empno: this.empno,
          otype: '7',
          btagcode: '9',
          rytype: this.rytype
        }
        DoRecord(data).then(res => { })
      }
    },
    copyWxnumber (mes) {
      var clipboard = new Clipboard('.wechat', {
        text: function () {
          return mes
        }
      })
      clipboard.on('success', e => {
        Toast.succeed('复制成功')
        let data = {
          suserid: this.suserid,
          empno: this.empno,
          otype: '6',
          btagcode: '9',
          rytype: this.rytype
        }
        DoRecord(data).then(res => { })
      })
    },
    shareCard () {
      this.isShareCard = true
    },
    cancelShare () {
      this.isShareCard = false
    },
    showPoster () {
      let cardref = eval('this.$refs.card0' + this.form.cardstyle)
      makeImg(cardref).then(res => {
        this.shareimg = res
        this.showCardPoster = true
      })
    },
    back () {
      this.showCardPoster = false
    },
    changePhone () {
      this.phoneForm = { username: '', mobile: '' }
      this.isChangePhoneShow = true
    },
    cancelChange () {
      this.isChangePhoneShow = false
    },
    submitChange () {
      if (!this.phoneForm.username) {
        Toast.info('请输入姓名')
        return
      }
      if (!this.phoneForm.mobile) {
        Toast.info('请输入电话')
        return
      }
      let data = {
        empno: this.empno,
        username: this.phoneForm.username,
        mobile: this.phoneForm.mobile,
        rytype: this.rytype
      }
      exchangeMobile(data).then(res => {
        Toast.succeed('交换成功！')
        this.isChangePhoneShow = false
      })
    },
    // tozxzx () {
    //   this.$router.push(
    //     `/Consult?askuserid=${this.user.userid}&empno=${this.empno}&emprytype=${this.rytype}&suserid=${this.suserid}&pagetype=9&news=1`
    //   )
    // }
  }
}
</script>
<style lang="stylus" scoped>
@import './index.styl';
</style>
